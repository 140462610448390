.c-home {

  &__tiles {
    margin: .3rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    height: 100%;

    &__tile-item {
      margin: auto;
      min-width: 100px;
      min-height: 100px;
      max-width: 130px;
      max-height: 130px;
      background-color: $gray-light;
      border: 1px solid #BDBDBD;

      // &__icon {
        
      // }

      &__text {
        text-align: center;
      }
    }
  }
}