.c-feedback {
  text-align: center; 
  padding: 0 1rem;
}

.c-feedback__star-rating {
  margin-bottom: 1rem;
  color: #a98556;
  font-size: 3rem;

  span {
    cursor: pointer;
  }
}