.c-login__logo {
  display: block;
  background-color: #fff; 
  padding-left: 2rem;
  padding-right: 2rem;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.c-login__reset {
  display: flex;
  justify-content: space-between;
  color: #000;
  text-decoration: none;
  padding: 1rem;
}

.login_H_txt{
  display: flex;
  align-items: center;
  justify-content: center;
}