.payment-methods {
  .list, .list-item {
    background-image: none;
  }
}

.c-payment-method__stripe-logo {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  > a {
    text-decoration: none;
    padding: 0 1rem;
    width: 10rem;

    > img {
      width: 100%;
    }
  }
}

.c-payment-method__stripe-wordmark {
  width: 20%;
  display: flex;
  align-items: center;

  > ons-icon {
    color: #5433FF;
  }

  > img {
    width: 100%;
  }
}

.c-payment-method__mastercard {
  display: inline-flex;
  background-color: #000000;
  width: 40px;
  height: 30px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

  > img {
    width: 100%;
  }
}

.c-payment-method__visa {
  display: inline-flex;
  width: 40px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

  > img {
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    width: 75%;
  }
}

.c-payment-method__item {
  padding-left: 0;
  border: 0;
  
  ons-card {
    width: 100%;
    padding-left: 1rem !important;
    border: 1px solid #333;
    padding: 0.5rem;
    margin-bottom: 0 !important;
    background: linear-gradient( to bottom,  #fff, #eee);
  }
}

.c-payment-method__item--selected {
  ons-card {
    background-color: #FFFFFF !important;
  }
}

.c-payment-method__item-icon {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &--start {
    justify-content: start;
  }

  &--end {
    justify-content: end;
  }
}

.c-payment-method__item-remove {
  background-color: transparent;
  color: #000000;
  border: none;
}