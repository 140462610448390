.c-card-list {
  padding: 0;
}

.c-card {
  position: relative;
  list-style-type: none;
  border-radius: 0.5rem;
  background-color: $gray-light;
  color: $white;
  height: 300px;
  padding: 1rem;
  padding-top: 0.5rem;
  transition: all 300ms ease-in-out;
  overflow: hidden;

  &:not(:first-child) {
    margin-top: -250px;
    border-top: 2px solid $gray-dark;

    &.c-card--voucher {
      margin-top: -240px;
    }
  }

  // &:hover {
  //   transform: scale(1.01);
  // }
}

// .c-card--voucher {
//   height: 280px;

//   &:not(:first-child) {
//     margin-top: -230px;
//   }
// }

.c-card--active {
  // z-index: 1;
  overflow: visible;
}

.slick-slide:not(.slick-active) {
  .c-slick-card {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .c-card {
    background-color: $black;
  }
}

.page__current-orders {
  .page__content {
    > div {
      overflow-x: visible !important;
    }
  }
}

.react-swipeable-view-container {
  // padding: 0 30px;

  > div {
    // padding: 0 10px;
  }

  > div[aria-hidden=true] {
    position: relative;
    left: 0px;
    right: -5px;
  }
}

.c-slick-card {
  padding: 0.5rem;

  .c-card {
    height: auto;
    text-align: center;
  }

  .c-card__name {
    margin-left: 0;
    border-bottom-left-radius: 0.5rem;
  }
}

.c-card__header {
  background-color: $secondary-dark;
  color: $white !important;
  padding: 1rem;
  border-radius: .5rem;
  border: 1px $gray-dark;
  margin-left: -1rem;
  margin-top: -0.5rem;
  width: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  h2 {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.c-card__name {
  text-align: left;
}

.c-card__name-brochure {
  display: block;
  font-size: 1rem;
}

.c-card__name-experience {
  display: block;
  margin-top: -0.25rem;
}

.c-card__wrap {
  text-align: center;
}

.c-card__logo {
  width: auto;
  height: 30px;
  margin-left: auto;
}

.c-card__banner {
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 1rem;
}

.c-card__intro {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  white-space: pre-line;
}

.c-card__description {
  margin-bottom: 1rem;
  white-space: pre-line;
}

.c-card__remove,
.c-card__close {
  margin-top: -0.5rem;
  margin-right: -1rem;
  align-self: flex-start;
  padding: 0.5rem 1rem;
  color: $black;
  font-size: 1.25rem;
}

.c-card__experience-intro,
.c-card__experience-description {
  white-space: pre-line;
  margin-bottom: 1rem;
}

.c-card__tickets {
  padding: 1rem 0;
  border-top: 2px solid $white;
  border-bottom: 2px solid $white;
}

.c-card-ticket {
  text-align: left;

  .c-number-input {
    flex-shrink: 0;
  }
}

.c-history__card {
  background-color: rgba($white, 0.75) !important;
}

.card.c-payment-methods__card {
  background-color: $white !important;
  border-radius: 2rem !important;
  padding: 1rem 0.4rem;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%) !important;

}

.c-payment-methods__card-name-input {
  letter-spacing: '0.025em';
  font-size: 16px;
  color: '#3a3a3a';
  font-weight: 500;
  letter-spacing: 0.025em;
  color-scheme: light only !important;

  ::placeholder {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.025em;
    font-size: 16px;
    color: #aab7c4;
  }
}

.c-payment-methods__card {
  margin-top: 0.5rem;
  background-color: $white !important;
  border-radius: 2rem !important;
  padding: 0.75rem 0 0.75rem 1rem;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%) !important;
}

.c-payment-methods__card--inner {
  flex-grow: 1;
}

.c-payment-methods__card-input-label {
  margin-left: 0.75rem;
  font-weight: 500;
}