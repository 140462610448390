.c-navbar {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;

  ons-button {
    padding: 0;
    background-color: #fff;
    color: $primary;
    font-size: 10px;
  }
}

.c-navbar__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-navbar__content--0 {
  background-color: $secondary-dark;
}

.page--wrapper .c-navbar__content {
  justify-content: flex-start;
  min-height: 65px;
  width: 100vw;
}

.c-navbar__logo {
  max-width: 110px;
  background-color: transparent !important;
  margin: 0.875rem 1.5rem 0.5rem;
  padding: 0;
}

.page.drink-form, .page.cart, .page.order-history, .page.user-profile, .page.preferred-beans, .page.payment-methods {
  .back-button__label {
    display: none;
  }
  .c-navbar__logo {
    margin-right: 0.25rem !important;
    margin-left: 1rem !important;
  }
}


.cart-button {
  color: $black !important;
  background-color: transparent !important;
  margin-right: .5rem;
}

.step-0.drink-form {
  #liquid_almost {
    visibility: hidden;
  }
}
.step-2.drink-form {
  #liquid_almost {
    visibility: visible;
  }
}
#liquid_almost {
  visibility: hidden;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  display: block;
}

:checked + .tabbar__button {
  color: #999;
}

.back-button {
  color: $black;
  margin: 0.5rem 0 0;
}

.back-button__icon {
  fill: $black;
}

.toolbar {
  background-color: rgba($primary, 0.9);
  background-image: none;
  color: white;
  padding-top: env(safe-area-inset-top);
  height: calc(65px + env(safe-area-inset-top));
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

  // &--material {
  //   height: calc(56px + env(safe-area-inset-top));
  // }
}

.toolbar-button {
  color: white;
}

.toolbar__title {
  // color: white;
  // font-family: 'Lobster', cursive;
  // font-size: 22px;
}

.toolbar + .page__background + .page__content {
  // top:  65px;
  top: calc(65px + env(safe-area-inset-top));
  padding-top: 1rem;
}

.toolbar.toolbar--material+.page__background+.page__content {
  top: calc(65px + env(safe-area-inset-top));
}

.toolbar + .page--material__background + .page--material__content {
  // top: calc(56px + env(safe-area-inset-top));
  top: calc(65px + env(safe-area-inset-top));
  padding-top: 1rem !important;
}

.tabbar {
  border-top: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.75);
  background-image: none;
  height: calc(65px + env(safe-area-inset-top));
}

.tabbar__content {
  bottom: calc(65px + env(safe-area-inset-top));
}

.tabbar__button {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 36px;
    height: 36px;
  }
}