.c-sidebar {

  &__name {
    color: #FFFFFF;
    margin-left: 1rem !important;
    margin-bottom: 2rem !important;
  }

  .page__background {
    max-width: 80%;
    background-color: $gray-darker;
  }

  .page__content {
    // max-width: 80%;
    background-color: $dark !important;
    padding: 0;
    
    ons-list {
      margin-top: 1.5rem;
    }

    p {
      color: #FFFFFF;
      margin: 0.5rem 0;
    }  
  }

  .list {
    background-image: none;
    margin-bottom: 1.2rem;
  }

  .list-item__center {
    border-bottom: none;
    background-image: none;
  }

  &__button-group {
    position: absolute;
    bottom: 0;
    width: calc(75vw - 32px);
    margin-bottom: 16px;
  
    .button--large--cta {
      position: relative;
      display: inline-block;
    }
  }

  a {
    text-decoration: none;
    color: #FFFFFF;
    margin-left: 1rem;
  }
}

.c-sidebar__layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  background-color: $secondary-dark;
}

.c-sidebar__list {
  padding: 1rem;
}

.c-sidebar__swipe {
  width: 19%;
  // background-image: url('../../images/app-bg-blurred.jpg');
  background-size: cover;
  background-position: center center;
}