html {
  overflow: hidden;
}

body {
  -webkit-overflow-scrolling: auto;
  color: white;
  background-color: #E1E1E1;
}

html,
body {
  min-height: 100%;
	min-height: calc(100% + constant(safe-area-inset-top));
	min-height: calc(100% + env(safe-area-inset-top));
}

#app {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.toolbar {
  background-color: $brown-light;
  box-shadow: none;
  height: calc(65px + env(safe-area-inset-top));
  @media all and (min-width: 1400px) {
    max-width: 50rem;
    margin: auto;
  }
}

.toolbar.toolbar__dark {
  background-color: $secondary-dark;
}

.page {
  color: $gray-darker;
  background-color: #EEE5DA !important;
}

.page__background {
  background-color: $brown-light;
  background-size: cover;
  background-position: center center;
  // filter: blur(8px);
  // -webkit-filter: blur(8px);
  // background-image: url('../images/app-bg-blurred.jpg');
}

.page.cart {
  .page__content, .toolbar {
    background-color: $brown-light !important;
  }
}


.toolbar+.page__background {
  top: 65px;
}

.page__content {
  padding: 1rem;
  background-color: $brown-light !important;
  @media all and (min-width: 1400px) {
    max-width: 50rem;
    margin: auto;
  }

  .c-home &, .directions & {
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', sans-serif;
    font-weight: 300;
  }
}

.page__content .page__content {
  // background-image: url(/images/GM_Rotorua_MTB_16.jpg) !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  // background-color: rgba(40,40,40, 0.35) !important;
}

.page__content .c-sidebar .page__content {
  background-image: none !important;
}

.c-donate,
.c-newsletter {
  .page__content {
    // background-image: url(/images/GM_MTB_Rotorua-279.jpg) !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
}

:root {
  --text-color: #000;
}

.progress-circular--material__primary {
  stroke: $primary;
}

.progress-circular__primary {
  stroke: $primary;
}

ons-modal {
  background-color: none;
}

.alert-dialog-button {
  color: #fff;
}

.card {
  margin: 0;
  margin-bottom: 1rem;
  background-color: rgba(white, 0.2);
  // background-color: white;
  box-shadow: none !important;
  color: $gray-darker;
  padding: 1.5rem;
}

h1 {
  font-weight: 700;
} 

.progress-bar {
  padding-top: .2rem;
  padding-bottom: .2rem;

  background-color: #FFFFFF;

  &__primary {
    background-color: $primary;
  }
}

.list {
  background-color: transparent;
}

ons-checkbox {
  margin-top: 14px;
  margin-bottom: 14px;
}

:checked + .checkbox__checkmark:before {
  background-color: $primary;
}

.page-loading {
  height: 100%;
  position: relative;

  ons-progress-circular {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }
}

ons-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 1.5rem !important;
}