@mixin aspectRatio($width, $height) {
  position: relative;

  &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ($height / $width) * 100%;
  }
}

@mixin aspectRatioContent() {
  @include aspectRatio(1, 1);

  > .o-aspect-ratio__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  }
}

.o-aspect-ratio {
  @include aspectRatioContent();
}

.o-aspect-ratio--16by9 {
  @include aspectRatio(16, 9);
}