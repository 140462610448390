$brown: #96754A;
$brown-medium: #bea380;
$brown-light: #EEE5DA;

$gray-light: #f6f6f6;
$gray-lighter: #fcfcfc;
$gray: #e8e8e9;
$gray-medium: #696969;
$gray-dark: #666666;
$gray-darker: #273136;
$green: #4DC2C2;
$green-dark: #2C4C48;
$red: #ED5E68;

$black: #000;

$primary: $black;
$secondary: #666;
$secondary-dark: #383838;

$dark: #222;
$light: #777;

$white: #FFFFFF;

$roboto: 'Roboto', sans-serif;