.c-form {
  background-color: transparent;
  padding: 0 1rem;
  border-radius: 0.5rem;

  img {
    width: 100%;
    height: auto;
    margin-top: 1rem;
  }

  &__icon {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiSvgIcon-root {
      margin-bottom: 16px;
    }
  }

  .MuiButton-text {
    color: $secondary-dark;
  }

  .c-form__row {  
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 57px;
  
    ons-select {
      box-sizing: border-box;
      background-color: #fff;
      padding: 0.25rem 0.5rem;
      width: 100%;
      border-radius: 4px;
    }
  
    ons-radio {
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
      margin-left: 0 !important;
      border-radius: 4px;
    }
  
    i {
      color: #ff0000;
      font-size: .9rem;
    }
  
    p {
      text-align: left;
    }
  
    .c-form__card {
      display: inline-block;
      width: 100%;
      background-color: #fff;
      padding: 0.75rem 0.5rem;
      border-radius: 4px;
      box-sizing: border-box;
    }
  
    ons-list-header {
      background-color: $primary;
      color: #fff;
      text-transform: none;
      font-size: 1rem;
      padding-top: .2rem;
      padding-bottom: .4rem;
    }

    &--material {
      ons-input {
        padding-top: .9rem;
        padding-bottom: .4rem;
      }
    }
  }

  .c-form__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    label {
      margin-left: .5rem !important;
    }
  }
  
  .c-form__family {
    display: inline-block;
    background-color: #fff;
    padding: .5rem;
    border-radius: 10px;
    min-width: 92vw;
    max-width: 92vw;
  
    p {
      color: #1f1f21;
      margin-bottom: 1.7rem;
    }
  
    ons-button {
      margin-right: .4rem;
    }
  
    ons-input {
      max-width: 95%;
    }
  
    ons-select {
      max-width: 95%;
      padding-left: 0;
      padding-right: 0;
      padding-top: .3rem;
      padding-bottom: .3rem;
    }
  }
  
  .c-form__familymember {
    width: 100%;
  
    ons-icon {
      max-width: 30%;
      float: left;
      margin-right: .4rem;
    }
  
    p {
      max-width: 30%;
      float: left;
      margin: .4rem;
      margin-left: .2rem;
    }
  
    button {
      float: right;
      background-color: #fff;
      color: #000;
      font-style: normal;
    }
  }
  
  .c-form__error {
    text-align: left;
    margin-left: .1rem;
  }
  
  .c-form__radiogroup {
    text-align: left;
  
    ons-radio {
      background-color: #fff;
      margin-left: .2rem;
    }
  
    ons-row {
      margin-bottom: .6rem;
    }
  
    .c-form__radiolabel {
      min-width: 85%;
      width: 85%;
    }
  
    .radio-button--material {
      background-color: transparent;
      box-shadow: none;
    }
  }
  
  .c-form__button-group {
    // position: absolute;
    // bottom: 0;
    // left: 16px;
    // width: calc(100vw - 32px);
  
    .button--large--cta {
      position: relative;
      display: inline-block;
    }
  }

  &__photo {
    display: grid;
    grid-template-columns: 1fr 1fr 5fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;    
    z-index: 0;

    p {
      color: #ffffff;
    }

    ons-icon {
      color: $green;
    }

    &__controls {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 3;
      z-index: 2;
      margin-top: auto;
      margin-bottom: auto;

      &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-left: .2rem;
        margin-right: .2rem;
        z-index: 3;

        ons-button {
          margin-left: 0.1rem;
          margin-right: 0.1rem;
          
        }
      }
    } 
  
    &__image {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 4;
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;

      &__selected {
        filter: brightness(50%);
      }
    }
  }
}

ons-input {
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
  border-radius: 2rem;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
}

.text-input--material__label {
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.text-input--material {
  background-image: none;
}

.check-button,
.check-button--selected {
  input {
    display: none;
  }
}

// .check-button {
//   background-color: #FFFFFF !important;
//   color: $primary !important;
// }

.c-incrementor {
  position: absolute;
  right: 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: bold;
    font-size: 1.3rem;
  }

  ons-button {
    min-width: 40px;
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
    box-shadow: none !important;
    color: #000000;
  }

  .button:disabled, 
  .button[disabled] {
    background-color: transparent;
    color: #696969;
  }

  .button:active {
    background-color: transparent;
  }

  &__count {
    margin-left: 1rem;
    margin-right: 1rem;
    line-height: 1;
  }
}

.c-form__card-error {
  color: $red;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
}

.c-form--order {
  h2,
  h3 {
    color: #000;
  }

}

.c-form__preferences {
  .check-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 1.5rem;
  }
  .check-button__text {
    display: inline-block;
    margin: 0.5rem 0 0;
    font-size: 1.125rem;
    font-weight: 300;
  }
}

.c-form__checkbox-group {
  .btn-group-toggle {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .check-button {
    display: flex;
    justify-content: space-between;
    padding: 0.125rem 1rem;
  }
  .check-button__text {
    display: inline-block;
    margin: 0.5rem 0.5rem 0 0;
    font-size: 0.875rem;
    font-weight: 300;
  }
}

.c-form__text-area { 
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  margin-bottom: 1.25rem;

  textarea {
    border: none;
    resize: none;
    font: inherit;
    font-weight: 400;
    width: 100%;
  }
}

.payment-methods {
  .c-form {
    padding: 2rem 1.5rem;
  }
  .c-form__row {
    margin: 1.5rem 0 0.5rem;
  }
}

.c-form__phone-input {
  display: flex;
  flex-direction: row;
  border-radius: 2rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.c-form__phone-input--active {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: none;
}

.c-form__phone-input-prefix {
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ddd;
}