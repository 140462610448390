.c-list-item {
  &__image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: .5rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;

    &__title {
      font-weight: bold;
      font-size: 1rem;
      margin-top: 0;
    }
    
    &__subtitle {
      font-size: 1rem;
    }

    &__icons {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      min-width: 60px;
      min-height: 40px;

      &__icon {
        min-width: 40px;
        max-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;  
      }
    }
  }
}

.list-item__center {
  padding-right: 0 !important;
  background-image: none;
}